.Section-margins {
  margin-left: 1vw;
  margin-right: 4vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.Section-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: azure;
  background-color: grey;
  text-decoration: underline;
  border-style: solid;
  border-width: thin;
  border-color: black;
  width: 100%;
  font-size: 0.75em;
  max-width: 600px;
  margin-top: 1em;
}

.Section-containerRow {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 600px;
}

.Section-containerCol {
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 600px;
}

.Section-container {
  max-width: 600px;
  width: 100%;
}

.Header-inputContainer {
  border-style:solid;
  border-width: thin;
  height: 4em;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.Header-labelContainer {
  color: azure;
  background-color: #656972;
  border-style: solid;
  border-width: thin;
  border-color: black;
  height: 4em;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
}

.Header-cslContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
}

.Header-csl {
  font-size: 1.89em; /* This size allows for the title to fit on one row for the iphone 5/SE */
}

.Header-input {
  width:94%;
}

.ClientServiceNotes {
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  resize: none;
  height:300px;
  margin-top: 0.05em;
  width: 99%;
  max-width: 600px;
}

.MealLog-textInput {
  height: 3em;
  resize: none;
  width: 95%;
}

.MealLog-inputContainer {
  border-style:solid;
  border-width: thin;
  height: 5em;
  width: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MealLog-labelContainer {
  color: azure;
  background-color: #656972;
  border-style: solid;
  border-width: thin;
  border-color: black;
  height: 5em;
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2px;
}

.MealLog-percentSelect {
  height: 100%;
}

